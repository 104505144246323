.Card {    
    min-width: 30vh;    
    min-height: 40vh;  
    align-content: center;
    align-items: center;
    justify-content: center;    
    margin: 10vh 20vh 10vh 20vh;
    box-shadow: 1px 4px 8px 0 rgba(0,0,0,0.2);  
    transition: 0.3s;
  }
  
  .Card:hover {
    box-shadow: 3px 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  .Full-screen-working-on-bg {   
    background-image: url("/public/assets/working-on-image.svg");
    display: flex;  
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  
  .Tittle-working-on {
    display: flex;    
    justify-content: center;
    align-items: center;
    text-align: center;  
    color: black;        
    /* font-family: Monaco; */
  }
  
  .Container {
    display: flex;
    flex-wrap: wrap;    
  }
  
  .Element-Left {
    flex: 1;    
    padding: 10px;
  }
  
  .Element-Right {
    flex: 2;
    padding: 10px;
  }

  .Autoresize-text {
    font-size: 2vh;
  }